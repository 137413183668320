import React from "react";

import "./Heading.css";

const Heading = ({ title, htmlTag, alignment, cssClass }) => {
  const defaultClassName = cssClass
    ? ["module module__heading", cssClass, `align-${alignment}`].join(" ")
    : `module module__heading align-${alignment}`;

  const titleTag = React.createElement(`${htmlTag}`, {
    className: "module__heading-title",
    dangerouslySetInnerHTML: {
      __html: title,
    },
  });

  return (
    <div className={defaultClassName}>
      <div className="module__box">{title !== null ? titleTag : null}</div>
    </div>
  );
};

export default Heading;
